import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе прибережні чартери яхт Gem
			</title>
			<meta name={"description"} content={"Де кожна хвиля розповідає історію"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе прибережні чартери яхт Gem"} />
			<meta property={"og:description"} content={"Де кожна хвиля розповідає історію"} />
			<meta property={"og:image"} content={"https://qovilexon.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qovilexon.com/img/free-icon-yacht-3605.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qovilexon.com/img/free-icon-yacht-3605.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="80px 72px 80px 72px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px" background="#eef1ff">
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						as="h1"
						margin="0px 0px 16px 0px"
						font="--headline1"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про нас
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
					Coastal Gem Yacht Charters почалася з простого бачення: зробити яхтинг доступним і приємним для кожного. З перших днів нашої діяльності, коли ми мали лише кілька човнів, і до сьогоднішнього повноцінного флоту, наша пристрасть до моря ніколи не згасала. Ми пишаємося тим, що пропонуємо неперевершений досвід, який поєднує в собі гострі відчуття відкритої води та спокій моря. Кожна яхта в нашій колекції розповідає історію пригод, відпочинку та незабутніх моментів.
					</Text>
				</Box>
			</Box>
			<Text
				margin="0px 0px 16px 0px"
				font="--headline2"
				color="--dark"
				md-text-align="center"
				sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
			>
				Чому плавати з нами?
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Експертне керівництво
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Подорожуйте з упевненістю, коли наші досвідчені шкіпери проведуть вас мальовничими водними шляхами.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Індивідуальні враження
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Від неквапливого одиночного плавання до яскравих групових святкувань - ми розробляємо кожну подорож, щоб задовольнити ваші бажання.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Сталий вітрильний спорт
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми прагнемо захищати морське середовище, гарантуючи, що наші пригоди будуть настільки ж екологічно чистими, наскільки й захоплюючими.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://qovilexon.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Наш флот
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Серцем Coastal Gem є наші яхти, які ретельно підтримуються в належному стані. Кожне судно обирається за його комфорт, продуктивність та здатність забезпечити безпечний і приємний відпочинок. Від витончених вітрильників до міцних крейсерів, наш флот оснащений найсучаснішими навігаційними технологіями та зручностями, які зроблять ваш час на морі по-справжньому особливим. Незалежно від того, чи плаваєте ви спокійними ранковими водами, чи спостерігаєте захід сонця з палуби, наші човни - це ваші ворота до феноменальної морської пригоди.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Прокладіть свій курс
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Вирушайте в подорож, де море стане вашим ігровим майданчиком. У Coastal Gem Yacht Charters ми віримо, що кожен клієнт може створити унікальну історію. Незалежно від того, чи шукаєте ви спокійне усамітнення або захоплюючу пригоду, наша команда прагне створити морський досвід, який підійде саме вам. Приєднуйтесь до нас, і нехай горизонти надихнуть вас на нову главу.

				</Text>
				<Button href="/contacts" type="link" text-decoration-line="initial" margin="20px 0px 0px 0px">
					Контакти
				</Button>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://qovilexon.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});